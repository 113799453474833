import React from 'react'

const NewForm = () => {
  return (
    <div>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdqcll_ZGJokdohN1wRdr8QMgsT7vOhZqfuOU_Q0YxqTCSIfg/viewform?embedded=true" width="100%" height="1000" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
  )
}

export default NewForm